import * as React from "react";
import { graphql, Link, navigate } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";
import Button from "react-bootstrap/Button";
import Ratio from "react-bootstrap/Ratio";
import ReactPlayer from "react-player";

import Footer from "../components/footer";
import GdprPanel from "../components/gdpr";
import Navigation from "../components/navigationVideoPage";
import Seo from "../components/seo";
import SpeakWithAdmissions from "../components/speakWithAdmissions";

import "../styles/video-center.scss";
import NewsletterSignup from "../components/newsletterSignUp";
import RelatedVideos from "../components/upNextVideos";

export default function VideoCenterSinglePage({ data }) {
  const post = data.post;
  const related = data.related;

  return (
    <>
      <SSRProvider>
        <div
          itemScope
          itemProp="VideoObject"
          itemType="https://schema.org/VideoObject"
          lang="en"
        >
          <Navigation />
          <div id="body" className="video-center single-page">
            <meta
              itemProp="uploadDate"
              content={post.frontmatter.datePublished}
            />
            <meta itemProp="duration" content={post.frontmatter.playTime} />
            <meta
              itemProp="name"
              content={`${post.frontmatter.title} | ${post.frontmatter.subheadline}`}
            />
            <meta
              itemProp="thumbnailUrl"
              content={post.frontmatter.videoThumb}
            />
            <link itemprop="embedUrl" href={post.frontmatter.videoLink} />
            <Container>
              <Row>
                <Col>
                  <Button
                    variant="link"
                    onClick={() => navigate(-1)}
                    className="styled-link"
                  >
                    Back
                  </Button>
                </Col>
              </Row>
              <Row className="mb-4 pb-3">
                <Col>
                  <Ratio aspectRatio="16x9">
                    <ReactPlayer
                      className="react-player"
                      url={post.frontmatter.videoLink}
                      width="100%"
                      height="100%"
                      controls="true"
                      playing="true"
                    />
                  </Ratio>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5 className="mb-2">{post.frontmatter.subheadline}</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <h1>{post.frontmatter.headline}</h1>
                  <div className="share-icons"></div>
                </Col>
                <Col xs={12} lg={6}>
                  <span className="video-description" itemProp="description">
                    <MDXProvider>
                      <MDXRenderer>{post.body}</MDXRenderer>
                    </MDXProvider>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <RelatedVideos
                tags={
                  post.frontmatter.tags &&
                  post.frontmatter.tags.toString().split(",")
                }
                limit={4}
                title={post.frontmatter.title}
              />
              {/*<Row className="mt-lg-4 student-profiles justify-content-around">
                            <Col xs={12} className="mb-mb-4 pb-3">
                                <p className="up-next">Up Next:</p>
                            </Col>
                            {
                                related.nodes.splice(Math.floor(Math.random()*related.nodes.length), 4).map((node, i) => {
                                    return (
                                        <Col key={i++} xs={12} md={5} xl={3} className="pt-0 mb-4">
                                            <Link to={`/${node.slug}`}>
                                                <div className="video-thumb mb-4">
                                                    <img src={`${node.frontmatter.videoThumb}?${new Date().getTime()}`} className="img-fluid" alt={`${node.frontmatter.title} thumbnail`} />
                                                    <div className="run-time">{ node.frontmatter.runTime }</div>
                                                    <div className="play-icon"><span><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z" fill="white"/></svg></span></div>
                                                </div>                               
                                                <h4 className="mb-2">{ node.frontmatter.title }</h4>
                                                <p>{ node.frontmatter.subheadline }</p>
                                            </Link>
                                        </Col>
                                    )
                                })
                            }
                        </Row>*/}
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <div id="bottom-contact">
                <Row className="py-4 py-xl-5 justify-content-center">
                  <Col md={6} className="mb-0 mb-md-4 mb-xl-0">
                    <SpeakWithAdmissions menuType="bottom-grey" />
                  </Col>
                  <Col md={6}>
                    <img
                      src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image.webp"
                      alt="learn more about meridian xl"
                      className="img-fluid d-none d-xl-block"
                    />
                    <img
                      src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-large.webp"
                      alt="learn more about meridian lg"
                      className="img-fluid d-none d-lg-block d-xl-none"
                    />
                    <img
                      src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-medium.webp"
                      alt="learn more about meridian md"
                      className="img-fluid d-none d-md-block d-lg-none"
                    />
                    <img
                      src="https://web-cdn.meridianuniversity.edu/site-content-images/content-library/mu-content-page-bottom-image-small.webp"
                      alt="learn more about meridian sm"
                      className="img-fluid d-block d-md-none"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Footer />
          <GdprPanel />
          <NewsletterSignup />
        </div>
      </SSRProvider>
    </>
  );
}

export const Head = ({ data }) => (
  <Seo
    title={data.post.frontmatter.metadata.Title}
    description={data.post.frontmatter.metadata.Description}
    pathname={data.post.frontmatter.metadata.Link}
  />
);

export const query = graphql`
  query ($slug: String!) {
    post: mdx(slug: { eq: $slug }) {
      frontmatter {
        heroBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
        }
        videoLink
        playTime
        datePublished
        videoThumb
        tags
      }
      body
      slug
    }
    related: allMdx(filter: { frontmatter: { tags: { in: ["video"] } } }) {
      nodes {
        id
        slug
        frontmatter {
          title
          headline
          subheadline
          videoThumb
          runTime
          tags
        }
      }
    }
  }
`;
