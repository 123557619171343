import { useStaticQuery, graphql } from "gatsby";

export const useGetVideos = () => {
  let { allMdx } = useStaticQuery(graphql`
    query GetAllVideos {
      allMdx(filter: { frontmatter: { tags: { in: ["video"] } } }) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              headline
              subheadline
              videoThumb
              runTime
              tags
            }
          }
        }
      }
    }
  `);

  return allMdx;
};
